import React, { useState } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import history from './history'
import LoginScreen from '../screens/loginScreen/loginScreen';
import ProfileSettingsScreen from '../screens/profileSettingsScreen/profileSettingsScreen';
import ProfileAdminSettingsScreen from '../screens/admin/profileAdminSettingsScreen/profileAdminSettingsScreen';
import TenantListScreen from '../screens/admin/tenantListScreen/tenantListScreen';
import TenantEditScreen from '../screens/admin/tenantEditScreen/tenantEditScreen';
import UserListScreen from '../screens/admin/userListScreen/userListScreen';
import UserEditScreen from '../screens/admin/userEditScreen/userEditScreen';
import ParkingExemptionScreen from '../screens/parkingExemptionScreen/parkingExemptionScreen';
import TenantAddScreen from '../screens/admin/tenantAddScreen/tenantAddScreen';
import UserAddScreen from '../screens/admin/userAddScreen/userAddScreen';
import { AuthContext } from './auth';
import PrivateRoute from './privateRoute';


const Routes = (props: any) => {
  const existingToken = jwt.decode(localStorage.getItem('jwt') || '');
  const [authTokens, setAuthTokens] = useState(existingToken);
  
  const setTokens = (data: any) => {
    //setAuthTokens(data);
  }

    return (
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginScreen} exact />
            <PrivateRoute path="/" component={ParkingExemptionScreen} pageTitle={'Parkgebührenbefreiung'} exact/>
            <PrivateRoute path="/profilesettings" component={ProfileSettingsScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/profileAdminSettings" component={ProfileAdminSettingsScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants" component={TenantListScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants/edit" component={TenantEditScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants/add" component={TenantAddScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users" component={UserListScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users/edit" component={UserEditScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users/add" component={UserAddScreen} pageTitle={'Parkgebührenbefreiung'} exact />
          </Switch>
        </Router>
      </AuthContext.Provider>
    )
  }
  
  
  
  
export default Routes

/*
import React from 'react';
import { Switch, Route, Router, Redirect  } from 'react-router-dom';
import history from './history'
import LoginScreen from '../screens/loginScreen/loginScreen';
import ProfileSettingsScreen from '../screens/profileSettingsScreen/profileSettingsScreen';
import ProfileAdminSettingsScreen from '../screens/admin/profileAdminSettingsScreen/profileAdminSettingsScreen';
import TenantListScreen from '../screens/admin/tenantListScreen/tenantListScreen';
import TenantEditScreen from '../screens/admin/tenantEditScreen/tenantEditScreen';
import UserListScreen from '../screens/admin/userListScreen/userListScreen';
import UserEditScreen from '../screens/admin/userEditScreen/userEditScreen';
import ParkingExemptionScreen from '../screens/parkingExemptionScreen/parkingExemptionScreen';
import { useDispatch } from 'react-redux';
import TenantAddScreen from '../screens/admin/tenantAddScreen/tenantAddScreen';
import UserAddScreen from '../screens/admin/userAddScreen/userAddScreen';


class Routes extends React.Component<any, any> {

    getSessionToken = () => {
      let connectTrue = true;
  
      if(localStorage.getItem('session_token') === null || localStorage.getItem('session_token') === undefined)
        connectTrue = false
  
      if(localStorage.getItem('user') === null || localStorage.getItem('user') === undefined)
        connectTrue = false
  
      return connectTrue;
    }
  
    render() {
      const PrivateRoute = ({ component: Component, pageTitle, ...rest }: any): any => (
        <Route {...rest} render={(props) => {
  
          if(!this.getSessionToken()) {
            return (<Redirect to='/login' />)
          }
  
            // init functions
            // initThemes();
  
         return (<Component {...props} />)
        }
        } />
      )
  
      const ToAppRoute = ({ component: Component, pageTitle, ...rest }: any): any => (
        <Route {...rest} render={(props) => (
          this.getSessionToken()
            ? <Redirect to='/dashboard' />
            : <Redirect to='/login' />
        )} />
      )
  
      return (
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginScreen} exact />
            <ToAppRoute  path="/" component={ParkingExemptionScreen} exact/>
            <PrivateRoute  path="/dashboard" component={ParkingExemptionScreen} exact/>
            <PrivateRoute path="/profilesettings" component={ProfileSettingsScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/profileAdminSettings" component={ProfileAdminSettingsScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants" component={TenantListScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants/edit" component={TenantEditScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/tenants/add" component={TenantAddScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users" component={UserListScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users/edit" component={UserEditScreen} pageTitle={'Parkgebührenbefreiung'} exact />
            <PrivateRoute path="/admin/users/add" component={UserAddScreen} pageTitle={'Parkgebührenbefreiung'} exact />
          </Switch>
        </Router>
      )
    }
  }
  
  
  
  
export default Routes
*/