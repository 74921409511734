import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        user: action.user
      }

    case LOGIN_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        user: null
      }

    default:
      return state
  }
}

export default reducer
