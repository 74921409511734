import React, { useState } from 'react';
import { Button, Form, Grid, Header, Image, Icon, Container, Table } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTenant as deleteTenantAPI, updateTenant as updateTenantAPI } from '../../../service/apis';

const TenantEditScreen: React.ComponentType<any> = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const params: any = location.state;
    const tenant = params.tenant;

    const [pendingForm, setPendingForm] = useState(false);
    const [exseBaseUrl, setExseBaseUrl] = useState(tenant.exseBaseUrl);
    const [exseUsername, setExseUsername] = useState(tenant.exseUsername);
    const [exsePassword, setExsePassword] = useState(tenant.exsePassword);

    const handleDeleteTenant = () => {
        setPendingForm(true)
        deleteTenantAPI(tenant._id)
        .then((res) => {
            setPendingForm(false)
            history.goBack()
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleUpdateTenant = () => {
        setPendingForm(true)
        const newData = { exseBaseUrl, exseUsername, exsePassword }
        updateTenantAPI(tenant._id, newData)
        .then((res) => {
            setPendingForm(false)
            history.goBack()
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb deleteTenantButtonFunc={handleDeleteTenant} />
                <Form loading={pendingForm}>
                    <Header as='h3'>Mandant bearbeiten</Header>
                    <br />
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Name</label>
                            <Form.Input fluid type="text" placeholder='Name' value={tenant.name} disabled />
                        </Form.Field>
                        <Form.Field width={3}>
                            <label>PLZ</label>
                            <Form.Input fluid type="text" placeholder='PLZ' value={tenant.postcode} disabled />
                        </Form.Field>
                        <Form.Field width={4}>
                            <label>Anzahl Benutzer</label>
                            <Form.Input fluid type="text" placeholder='0' value={tenant.countOfUsers} disabled />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Endpoint URL' type="text" placeholder='Endpoint URL' value={exseBaseUrl} onChange={(e) => setExseBaseUrl(e.target.value)} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='API User' type="text" placeholder='Mediennummer' value={exseUsername} onChange={(e) => setExseUsername(e.target.value)} />
                        <Form.Input fluid label='API Passwort' type="text" placeholder='Mediennummer' value={exsePassword} onChange={(e) => setExsePassword(e.target.value)} />
                    </Form.Group>
                    <br /><br />
                    <Button type='submit' floated='right' onClick={handleUpdateTenant} positive>Speichern</Button>
                    <Button type='submit' floated='right' onClick={() => history.goBack()} >Abbrechen</Button>
                </Form>
            </Container>
        </div>
    )
}

export default TenantEditScreen;