import React, { FC } from 'react';
import { Button, Form, Grid, Header, Image, Segment, Container, Item } from 'semantic-ui-react'
import TopBar from '../../../../components/topBar/topBar';
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMediumDataInit as getMediumDataInitAction } from '../../../../store/getMediumData/action';
import { postCreditServiceInit as postCreditServiceInitAction } from '../../../../store/postCreditService/action';

const MediumResultWidget: FC<any> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const mediumData = useSelector((state: any) => state.getMediumData);
    const postCreditService = useSelector((state: any) => state.postCreditService);

    const pressNewMediumButton = () => {
        dispatch(getMediumDataInitAction());
        dispatch(postCreditServiceInitAction());
    }

    const pressRetryButton = () => {
        dispatch(postCreditServiceInitAction());
    }

    if(postCreditService.success){
        return (
            <div>
                <Breadcrumb backButtonFunc={pressNewMediumButton} />
                <Grid columns='equal'>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form>
                            <Header as='h3'>Die Parkgebührenbefreiung war erfolgreich!</Header>
                            <br />
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column mobile={8} tablet={6} computer={4}>
                                        Medium: 
                                    </Grid.Column>
                                    <Grid.Column>
                                        {postCreditService.data.MediumNumber}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={8} tablet={6} computer={4}>
                                        Befreiungsbetrag: 
                                    </Grid.Column>
                                    <Grid.Column>
                                        {((postCreditService.data.Amount / 100) + '').replace(/[.]/g, ',')} €
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br /> <br />
                            <Button type='submit' floated='right' onClick={pressNewMediumButton}>Neues Medium befreien</Button>
                            <br/> <br/>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        )
    } else {
        return (
            <div>
                <Breadcrumb backButtonFunc={pressNewMediumButton} />
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form>
                            <Header as='h3'>Die Parkgebührenbefreiung konnte nicht durchgeführt werden.</Header>
                            <br />
                            <Item.Group>
                                <Item>
                                    <Item.Content>Medium</Item.Content>
                                    <Item.Content>{mediumData.data.mediumNumber}</Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>Befreiungsbetrag</Item.Content>
                                    <Item.Content>{((postCreditService.params / 100) + '').replace(/[.]/g, ',')} €</Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>Fehlermeldung</Item.Content>
                                    <Item.Content>Befreiung ist für diesen Parktarif nicht möglich.</Item.Content>
                                </Item>
                            </Item.Group>
                            <Button type='submit' floated='right' onClick={pressRetryButton}>Noch einmal versuchen</Button>
                            <Button type='submit' floated='right' onClick={pressNewMediumButton}>Neues Medium befreien</Button>
                            <br/> <br/>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

export default MediumResultWidget;