import React from 'react';
import Routes from "./navigation/routes";
import 'semantic-ui-css/semantic.min.css'
import './App.css';

const App  = () => {
    return (
      <Routes />
    )
}

export default App;
