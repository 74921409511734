import React, { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Form, Header, Container, Grid } from "semantic-ui-react";

import TopBar from "../../../components/topBar/topBar";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import TenantDropDown from "../../../components/forms/tenantDropDown/tenantDropDown";
import { DiscountValues } from "../../../components/forms/discountValues.tsx/discountValues";
import { DeviceIDs } from "../../../components/forms/deviceIDs/deviceIDs";
import {
  deleteUser as deleteUserAPI,
  updateUser as updateUserAPI,
} from "../../../service/apis";
import UserRoleDropDown from '../../../components/forms/userRoleDropDown/userRoleDropDown';
import {roleTypes} from '../../../interfaces/roleType';

export interface DiscountValue {
  discountValue: number;
  label: string;
}

const UserEditScreen: FC<any> = () => {
  const history = useHistory();
  const location = useLocation();
  const params: any = location.state;
  const user = params.user;

  const [pendingForm, setPendingForm] = useState(false);
  const [tenantID, setTenantID] = useState(user.tenantID);
  const [userRole, setUserRole] = useState(user.role);
  const [password, setPassword] = useState("*****");
  const [passwordConfirm, setPasswordConfirm] = useState("*****");
  const [exseOriginatorId, setExseOriginatorId] = useState(
    user.exseOriginatorId
  );

  const [discountValues, setDiscountValues] = useState<DiscountValue[]>(
    user.discountValues || []
  );
  const [deviceIDs, setDeviceIDs] = useState<string[]>(user.deviceIDs || []);

  const handleDeleteUser = () => {
    setPendingForm(true);
    deleteUserAPI(user._id)
      .then(() => {
        setPendingForm(false);
        history.goBack();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleUpdateUser = () => {
    if (password !== passwordConfirm) {
      alert("Passwort muss gleich sein");
      return;
    }

    setPendingForm(true);
    const newPassword =
      password === "*****" || password === "" ? null : { password };
    const newTenantID = user.tenantID !== tenantID ? { tenantID } : null;
    const newUserRole = user.role !== userRole ? { userRole } : null;
    const newExseOriginatorId =
      user.exseOriginatorId !== exseOriginatorId ? { exseOriginatorId } : null;
    const newDiscountValues = {
      discountValues: discountValues.filter(
        ({ discountValue }) => discountValue
      ),
    };
    const newDeviceIDs = { deviceIDs: deviceIDs.filter(Boolean) };
    const newData = {
      ...newPassword,
      ...newTenantID,
      ...newUserRole,
      ...newExseOriginatorId,
      ...newDiscountValues,
      ...newDeviceIDs,
    };
    updateUserAPI(user._id, newData)
      .then(() => {
        setPendingForm(false);
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSetTenantID = (value: any) => setTenantID(value);

  return (
    <div>
      <TopBar />
      <Container>
        <Breadcrumb deleteUserButtonFunc={handleDeleteUser} />
        <Form loading={pendingForm}>
          <Header as="h3">Benutzer bearbeiten</Header>
          <br />
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Benutzername"
              type="text"
              placeholder="Name"
              value={user.username}
              disabled
            />
            {user.role === roleTypes.ADMINISTRATOR &&
                <TenantDropDown onChange={handleSetTenantID} value={tenantID}/>
            }
            <UserRoleDropDown user={user} onChange={(v: any) => setUserRole(v)} value={userRole} />
            <Form.Input
              fluid
              label="Kundennummer"
              type="text"
              placeholder="0.0.0"
              value={exseOriginatorId}
              onChange={(e) => setExseOriginatorId(e.target.value)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Neues Passwort"
              type="text"
              placeholder="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Input
              fluid
              label="Passwort wiederholen"
              type="text"
              placeholder="Passwort"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Form.Group>
          <br />
          <Grid>
            <Grid.Row columns={2} className="stackable">
              <DiscountValues
                discountValues={discountValues}
                setDiscountValues={setDiscountValues}
                isButtonDisabled={discountValues.length > 5}
              />
              <DeviceIDs deviceIDs={deviceIDs} setDeviceIDs={setDeviceIDs} />
            </Grid.Row>
          </Grid>
          <br />
          <br />
          <Button
            type="submit"
            floated="right"
            onClick={handleUpdateUser}
            positive
          >
            Speichern
          </Button>
          <Button
            type="submit"
            floated="right"
            onClick={() => history.goBack()}
          >
            Abbrechen
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default UserEditScreen;
