import axios from 'axios';

// 20 seconds timeout
const customAxios = axios.create({
  timeout: 20000,
});

// Add custom headers
const requestHandler = (request: any) => {
  request.headers = {
    ...request.headers,
    Authorization: 'Basic ' + btoa(process.env.REACT_APP_API_AUTH_USERNAME + ':' + process.env.REACT_APP_API_AUTH_PASSWORD),
    jwt: localStorage.getItem('jwt'),
  }

  return request;
};

// Handle 401 response
const responseHandler = (response: any) => {
  if (response.status === 401) {
    window.location.href = '/';
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
