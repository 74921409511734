import React, { FC } from 'react';
import { Button, Icon, Grid, Header, Image, Message, Container, Menu } from 'semantic-ui-react';
import { logout as logoutAction } from '../../store/auth/action';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {roleTypes} from '../../interfaces/roleType';

const TopBar: FC<any> = ({ pageTitle }) => {
    const history = useHistory();
    const auth = useSelector((state: any) => state.auth);
    const navigation = useSelector((state: any) => state.navigation);

    if(!auth.user || !navigation){
        return null;
    }

    const onClickUserProfile = () => {
        if (auth.user.role === roleTypes.ADMINISTRATOR || roleTypes.TENANT_ADMIN)
            history.push('/profileAdminSettings')
        else
            history.push('/profilesettings')
    }

    return (
        <Menu /*stackable*/ pointing secondary>
            <Container>
                <Menu.Item>
                    <p>{navigation.pageTitle}</p>
                </Menu.Item>

                <Menu.Menu position='right'>
                    <Menu.Item>
                        <p style={{ cursor: 'pointer' }} onClick={onClickUserProfile}>Benutzer: {auth.user.username}</p>
                    </Menu.Item>
                    <Menu.Item>
                        <Button onClick={() => { logoutAction() }}>Abmelden</Button>
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    )
}

export default TopBar;
