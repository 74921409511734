import { SHOW_MESSAGE, CLOSE_MESSAGE } from './types'

const show_Message = (messageCategory: number, title: string, subTitle: string) => {

    // 0 = neutral
    // 1 = positive
    // 2 = negative
    // 3 = info

    return {
        type: SHOW_MESSAGE,
        messageCategory: messageCategory,
        title: title,
        subTitle: subTitle,
    }
}

export const closeMessage = () => {
  return {
    type: CLOSE_MESSAGE
  }
}

export const showMessage = (messageCategory: number, title: string, subTitle: string) => {
    return (dispatch: any) => {
        setTimeout(() => dispatch(closeMessage()), 3000);
        dispatch(show_Message(messageCategory, title, subTitle))
    }
}