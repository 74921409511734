import React, { useEffect } from 'react';
import { Button, Form, Grid, Header, Image, Icon, Container, Table } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers as fetchUsersAction } from '../../../store/getUsers/action';
import { useHistory } from 'react-router-dom';
import { fetchTenants } from '../../../store/getTenants/action';

const UserListScreen: React.ComponentType<any> = () => {
    const dispatch = useDispatch(); 
    const history = useHistory();
    const getUsers = useSelector((state: any) => state.getUsers);
    const getTenants = useSelector((state: any) => state.getTenants);

    useEffect(
        () => {
            dispatch(fetchUsersAction());
          return () => {};
        }, []
    );

    useEffect(
        () => {
            dispatch(fetchTenants());
          return () => {};
        }, []
    );

    const renderRow = (user: any) => {
        const findTenantName = getTenants.data ? getTenants.data.find((tenant: any) => tenant._id === user.tenantID) : null

        return (
            <Table.Row key={user?._id}>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{ findTenantName && findTenantName.name ? findTenantName.name : 'Nicht gefunden' }</Table.Cell>
                <Table.Cell>{user.lastLoginAt}</Table.Cell>
                <Table.Cell>
                    <div 
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push({pathname: '/admin/users/edit', state: { user: user } })}
                    >
                        <Icon name='pencil' />
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb isAddUserButton />
                <Form loading={getUsers.pending}>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Benutzername</Table.HeaderCell>
                                <Table.HeaderCell>Mandant</Table.HeaderCell>
                                <Table.HeaderCell>Letzte Anmeldung</Table.HeaderCell>
                                <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                getUsers.data && getUsers.data.map((user: any) => renderRow(user))
                            }
                        </Table.Body>
                    </Table>
                </Form>
            </Container>
        </div>
    )
}

export default UserListScreen;