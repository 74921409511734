import React from 'react';
import { Button, Form, Grid, Header, Image, Container, Checkbox } from 'semantic-ui-react'
import TopBar from '../../components/topBar/topBar';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { useSelector } from 'react-redux'
import { EnterMediumWidget, EnterAmountWidget, MediumResultWidget } from './components'
import Message from '../../components/message/message';

const ParkingExemptionScreen: React.ComponentType<any> = () => {
    const mediumData = useSelector((state: any) => state.getMediumData);
    const postCreditService = useSelector((state: any) => state.postCreditService);

    const getContent = () => {

        if(mediumData.success === false){
            return <EnterMediumWidget />
        }

        if(mediumData.success && !postCreditService.success && !postCreditService.error){
            return <EnterAmountWidget />
        }

        return <MediumResultWidget />;
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Message />
                {getContent()}
            </Container>
        </div>
    )
}

export default ParkingExemptionScreen;