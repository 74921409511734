import React, { useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment, Container, Item } from 'semantic-ui-react'
import TopBar from '../../components/topBar/topBar';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { useSelector } from 'react-redux';
import { updateUserPassword as updateUserPasswordAPI } from '../../service/apis';

const ProfileSettingsScreen: React.ComponentType<any> = () => {
    const auth = useSelector((state: any) => state.auth);
    const [newPassword, setNewPassword]: any = useState(null);
    const [newPassword2, setNewPassword2]: any = useState(null);

    if(!auth.user){
        return null;
    }

    const updateUserPassword = () => {
        if(newPassword === newPassword2){
            updateUserPasswordAPI("5fac64d44be3f640a867b34a", newPassword) // FIXME auth.user._id
        }
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb />
                <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form>
                            <Header as='h3'>Einstellungen</Header>
                            <br />
                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>Mandant</Grid.Column>
                                        <Grid.Column>{auth.user.tenant.name}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Kundennummer</Grid.Column>
                                        <Grid.Column>{auth.user.exseOriginatorId}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Benutzername</Grid.Column>
                                        <Grid.Column>{auth.user.username}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Passwort</Grid.Column>
                                        <Grid.Column>***********</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Neues Passwort</Grid.Column>
                                        <Grid.Column>
                                            <input
                                                type='password'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Passwort wiederholen</Grid.Column>
                                        <Grid.Column>
                                            <input
                                                type='password'
                                                value={newPassword2}
                                                onChange={(e) => setNewPassword2(e.target.value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column></Grid.Column>
                                        <Grid.Column>
                                            <Button type='submit' floated='right' onClick={updateUserPassword} >Passwort ändern</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                        </Form>
                    </Grid.Column>
            </Container>
        </div>
    )
}

export default ProfileSettingsScreen;
