import React, { FC, useEffect } from 'react';
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenants } from '../../../store/getTenants/action';

const TenantDropDown: FC<any> = ({ onChange, value }) => {
    const dispatch = useDispatch();
    const getTenants = useSelector((state: any) => state.getTenants);

    useEffect(
        () => {
            dispatch(fetchTenants());
          return () => {};
        }, []
    );

    const tenantsOptions = getTenants.data ? getTenants.data.map((tenant: any) => {
        return {
            key: tenant._id,
            text: tenant.name,
            value: tenant._id
        }
    }) : null

    return (
        <Form.Dropdown
            fluid
            label='Mandant'
            placeholder='State'
            search
            selection
            loading={getTenants.pending}
            options={tenantsOptions}
            value={value}
            onChange={(e: any, { value }: any) => onChange(value)}
            style={{ marginTop: 4 }}
        />
    )
}

export default TenantDropDown;
