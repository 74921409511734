import React, { FC, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Container, Checkbox } from 'semantic-ui-react'
import TopBar from '../../../../components/topBar/topBar';
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMediumData } from '../../../../store/getMediumData/action';

const EnterMediumWidget: FC<any> = () => {
    const dispatch = useDispatch();
    const mediumData = useSelector((state: any) => state.getMediumData);
    const [mediumNumber, setMediumNumber]: any = useState('');

    const checkMediumData = () => {
        dispatch(fetchMediumData(mediumNumber))
    }

    return (
        <div>
            <Breadcrumb isBackButtonDisabled />
            <Form loading={mediumData.pending}>
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Header as='h3'>Mediennummer eingeben</Header>
                        <br />
                        <Form.Group widths='equal'>
                            <Form.Input 
                                fluid
                                label='Mediennummer'
                                placeholder='Mediennummer'
                                value={mediumNumber}
                                onChange={(e) => setMediumNumber((e.target.value).replace(/[^\d]/g, ''))}
                            />
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column mobile={1} tablet={16} computer={8}></Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Button 
                            type='submit'
                            floated='right'
                            disabled={mediumNumber === ''}
                            onClick={checkMediumData}>
                            Bestätigen
                        </Button>
                    </Grid.Column>
                </Grid>
            </Form>
        </div>
    )
}

export default EnterMediumWidget;