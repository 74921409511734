import { POST_CREDIT_SERVICE_PENDING, POST_CREDIT_SERVICE_SUCCESS, POST_CREDIT_SERVICE_ERROR, POST_CREDIT_SERVICE_INIT } from './types'
import { postCreditService as postCreditServiceAPI } from '../../service/apis'
import { showMessage as showMessageAction } from '../message/action'
import store from '../store'

const postCreditServicePending = () => {
  return {
    type: POST_CREDIT_SERVICE_PENDING,
  }
}

const postCreditServiceSuccess = (data: any, mediumNumber: number, creditAmount: number, exseOriginatorId: string) => {
  return {
    type: POST_CREDIT_SERVICE_SUCCESS,
    data,
    params: { mediumNumber, creditAmount, exseOriginatorId }
  }
}

const postCreditServiceError = (mediumNumber: number, creditAmount: number, exseOriginatorId: string) => {
  return {
    type: POST_CREDIT_SERVICE_ERROR,
    params: { mediumNumber, creditAmount, exseOriginatorId }
  }
}

export const postCreditServiceInit = () => {
  return {
    type: POST_CREDIT_SERVICE_INIT
  }
}

export const postCreditService = (mediumNumber: number, creditAmount: number) => {
    const tenantID = store.getState().auth.user.tenantID;
    const exseOriginatorId = store.getState().auth.user.exseOriginatorId

    return (dispatch: any) => {
      dispatch(postCreditServicePending())
      postCreditServiceAPI(tenantID, mediumNumber, creditAmount, exseOriginatorId)
        .then(async (resp: any) => {
          dispatch(postCreditServiceSuccess(resp.data.data, mediumNumber, creditAmount, exseOriginatorId))
        })
        .catch((err: any) => {
          dispatch(postCreditServiceError(mediumNumber, creditAmount, exseOriginatorId))
          dispatch(showMessageAction(2, 'Gutschrift konnte nicht erfolgen', err + ''))
        })
    }
}