import React, { useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment, Container, Item, Menu } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useSelector } from 'react-redux';
import { updateUserPassword as updateUserPasswordAPI } from '../../../service/apis';
import { useHistory } from 'react-router-dom';
import {roleTypes} from '../../../interfaces/roleType';

const ProfileAdminSettingsScreen: React.ComponentType<any> = () => {
    const history = useHistory();
    const auth = useSelector((state: any) => state.auth);
    const [newPassword, setNewPassword]: any = useState('');
    const [newPassword2, setNewPassword2]: any = useState('');

    if(!auth.user){
        return null;
    }

    const updateUserPassword = () => {
        if(newPassword === newPassword2){
            updateUserPasswordAPI(auth.user._id, newPassword);
        }
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb />
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Form>
                            <Header as='h3'>Einstellungen</Header>
                            <Segment>
                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>Mandant</Grid.Column>
                                        <Grid.Column>{auth.user.tenant.name}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Benutzername</Grid.Column>
                                        <Grid.Column>{auth.user.username}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Passwort</Grid.Column>
                                        <Grid.Column>***********</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Neues Passwort</Grid.Column>
                                        <Grid.Column>
                                            <input
                                                type='password'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Passwort wiederholen</Grid.Column>
                                        <Grid.Column>
                                            <input
                                                type='password'
                                                value={newPassword2}
                                                onChange={(e) => setNewPassword2(e.target.value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column></Grid.Column>
                                        <Grid.Column>
                                            <Button type='submit' floated='right' onClick={updateUserPassword} >Passwort ändern</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>
                    </Grid.Column>
                    { (auth.user.role === roleTypes.ADMINISTRATOR || auth.user.role === roleTypes.TENANT_ADMIN) &&
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Grid>
                              <Grid.Row>
                                  <Header as='h3'>Administration</Header>
                              </Grid.Row>
                              <Grid.Row>

                                  {auth.user.role === 'admin' &&
                                    <Button onClick={() => {
                                        history.push('admin/tenants')
                                    }}>Mandanten verwalten</Button>
                                  }

                                  <Button onClick={() => {
                                      history.push('admin/users')
                                  }}>Benutzer verwalten</Button>
                              </Grid.Row>
                          </Grid>
                      </Grid.Column>
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default ProfileAdminSettingsScreen;
