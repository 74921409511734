import { GET_TENANTS_PENDING, GET_TENANTS_SUCCESS, GET_TENANTS_ERROR, GET_TENANTS_INIT } from './types'
import { getTenants as getTenantsAPI, deleteTenant as deleteTenantAPI } from '../../service/apis/'
import { showMessage as showMessageAction } from '../message/action'

const getTenantsPending = () => {
  return {
    type: GET_TENANTS_PENDING,
  }
}

const getTenantsSuccess = (data: any) => {
  return {
    type: GET_TENANTS_SUCCESS,
    data,
  }
}

const getTenantsError = () => {
  return {
    type: GET_TENANTS_ERROR
  }
}

export const getTenantsInit = () => {
  return {
    type: GET_TENANTS_INIT
  }
}

export const fetchTenants = () => {
    return (dispatch: any) => {
      dispatch(getTenantsPending())
      getTenantsAPI()
        .then(async (resp: any) => {
            dispatch(getTenantsSuccess(resp.data.data))
        })
        .catch((err: any) => {
          dispatch(getTenantsError())
        })
    }
}