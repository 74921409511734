import { SET_PAGE_TITLE } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      }

    default:
      return state
  }
}

export default reducer
