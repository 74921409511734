import { SHOW_MESSAGE, CLOSE_MESSAGE } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        isVisible: true,
        messageCategory: action.messageCategory,
        title: action.title,
        subTitle: action.subTitle
      }

    case CLOSE_MESSAGE:
      return {
        ...state,
        isVisible: false,
        messageCategory: null,
        title: null,
        subTitle: null
      }

    default:
      return state
  }
}

export default reducer