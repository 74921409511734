import customAxios from '../interceptors';

const updateUserPassword = (userID: string, password: string) => {
  return customAxios.patch(`${process.env.REACT_APP_API_URL}/users/${userID}/password/${password}`)
    .then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
}

export default updateUserPassword;
