import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { setAuth } from '../store/auth/action';
import { useAuth } from './auth';

const PrivateRoute = ({ component: Component, pageTitle, ...rest }: any): any => {
  const { authTokens } = useAuth();
  const dispatch = useDispatch();
  const [ connectionAllowed, setConnectionAllowed ] = useState(true);
  const auth = useSelector((state: any) => state.auth);

  //console.log('authTokens : ', authTokens)

  if (connectionAllowed) {
    if (!authTokens || Object.values(authTokens).length === 0) {
      setConnectionAllowed(false);
    }
  }

  useEffect(
    () => {
      if (!auth.success || !auth.user) {
        dispatch(setAuth(authTokens?.data))
      }

      return () => { };
    }, []
  );

  return (
    <Route
      { ...rest }
      render={ props =>
        connectionAllowed ? (
          <Component { ...props } />
        ) : (
          <Redirect to='/login'/>
        )
      }
    />
  );
}

export default PrivateRoute;