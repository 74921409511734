import { GET_USERS_PENDING, GET_USERS_SUCCESS, GET_USERS_ERROR, GET_USERS_INIT } from './types'
import { getUsers as getUsersAPI } from '../../service/apis/'
import { showMessage as showMessageAction } from '../message/action'

const getUsersPending = () => {
  return {
    type: GET_USERS_PENDING,
  }
}

const getUsersSuccess = (data: any) => {
  return {
    type: GET_USERS_SUCCESS,
    data,
  }
}

const getUsersError = () => {
  return {
    type: GET_USERS_ERROR
  }
}

export const getUsersInit = () => {
  return {
    type: GET_USERS_INIT
  }
}

export const fetchUsers = () => {
    return (dispatch: any) => {
      dispatch(getUsersPending())
      getUsersAPI()
        .then(async (resp: any) => {
            dispatch(getUsersSuccess(resp.data.data))
        })
        .catch((err: any) => {
          dispatch(getUsersError())
        })
    }
}