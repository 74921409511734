import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Func0 } from 'redux';
import { Button, Icon, Grid, Header, Image, Message, Container, Checkbox } from 'semantic-ui-react'

const Breadcrumb: FC<IBreadcrumgProps> = ({ isBackButtonDisabled, backButtonFunc, isAddTenantButton, deleteTenantButtonFunc, isAddUserButton, deleteUserButtonFunc }) => {
    let history = useHistory();

    const renderBackButton = () => {
        
        if(isBackButtonDisabled)
            return null;

        return (
            <Button icon labelPosition='left' onClick={backButtonFunc ? backButtonFunc : () => history.goBack()}>
                <Icon name='arrow left' />
                Zurück
            </Button>
        )
    }

    const renderAddTenantButton = () => {
        if(!isAddTenantButton)
            return null;

        return(
            <Button icon labelPosition='left' style={{ float: 'right' }} onClick={() => history.push('/admin/tenants/add')}>
                <Icon name='add' />
                Neuer Mandant
            </Button>
        )
    }

    const renderDeleteTenantButtonFunc = () => {
        if(!deleteTenantButtonFunc)
            return null
        
        return(
            <Button icon negative labelPosition='left' style={{ float: 'right' }} onClick={deleteTenantButtonFunc}>
                <Icon name='remove' />
                Mandanten löschen
            </Button>
        )  
    }

    const renderDeleteUserButtonFunc = () => {
        if(!deleteUserButtonFunc)
        return null
    
        return(
            <Button icon negative labelPosition='left' style={{ float: 'right' }} onClick={deleteUserButtonFunc}>
                <Icon name='remove' />
                Benutzer löschen
            </Button>
        )  
    }

    const renderAddUserButton = () => {
        if(!isAddUserButton)
            return null;

        return(
            <Button icon labelPosition='left' style={{ float: 'right' }} onClick={() => history.push('/admin/users/add')}>
                <Icon name='add' />
                Neuer Benutzer
            </Button>
        )
    }
    
    return (
        <div>
            <br />
            <br />
            {renderBackButton()}
            {renderAddTenantButton()}
            {renderAddUserButton()}
            {renderDeleteTenantButtonFunc()}
            {renderDeleteUserButtonFunc()}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default Breadcrumb;

interface IBreadcrumgProps {
    isBackButtonDisabled?: boolean,
    backButtonFunc?: () => void
    isAddTenantButton?: boolean,
    deleteTenantButtonFunc?: () => void,
    deleteUserButtonFunc?: () => void,
    isAddUserButton?: boolean
}