import React, { Dispatch, memo, SetStateAction, useCallback } from "react";
import { Button, Form, Grid, Icon } from "semantic-ui-react";

import { DiscountValue } from "../../../screens/admin/userEditScreen/userEditScreen";

interface DiscountValuesProps {
  discountValues: DiscountValue[];
  setDiscountValues: Dispatch<SetStateAction<DiscountValue[]>>;
  isButtonDisabled: boolean;
}

export const DiscountValues: React.FC<DiscountValuesProps> = memo(
  ({ discountValues, setDiscountValues, isButtonDisabled = false }) => {
    const handleChangeDiscountValue = useCallback(
      (value, index) => {
        let formatedValue = Number(value).toFixed(2);
        if (Number(formatedValue) >= 999.99) {
          formatedValue = "999.99";
        }
        discountValues[index].discountValue = Number(formatedValue);
        setDiscountValues([...discountValues]);
      },
      [discountValues, setDiscountValues]
    );

    const handleChangeDiscountLabel = useCallback(
      (value, index) => {
        discountValues[index].label = value;
        setDiscountValues([...discountValues]);
      },
      [discountValues, setDiscountValues]
    );

    const handleAddDiscountValue = useCallback(() => {
      discountValues.push({ discountValue: 0, label: "" });
      setDiscountValues([...discountValues]);
    }, [discountValues, setDiscountValues]);

    const handleDeleteDiscountValue = useCallback(
      (i) => {
        setDiscountValues([
          ...discountValues.filter((_, index) => index !== i),
        ]);
      },
      [discountValues, setDiscountValues]
    );

    if (!discountValues.length) {
      handleAddDiscountValue();
    }

    return (
      <Grid.Column>
        <Grid>
          {discountValues.map(({ discountValue, label }, i) => (
            <Grid.Row key={i} columns={3} verticalAlign="middle">
              <Grid.Column width={7}>
                <Form.Input
                  icon={"euro sign"}
                  iconPosition={"left"}
                  value={discountValue}
                  placeholder="0"
                  type={"number"}
                  min={0}
                  step={0.01}
                  max="999.99"
                  onChange={(e) => {
                    handleChangeDiscountValue(e.target.value, i);
                  }}
                  label={i === 0 && "Befreiungs-Betrag"}
                />
              </Grid.Column>
              <Grid.Column width={7}>
                <Form.Input
                  value={label}
                  type={"text"}
                  onChange={(e) => {
                    handleChangeDiscountLabel(e.target.value, i);
                  }}
                  label={i === 0 && "Button-Text"}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                {i !== 0 && (
                  <Icon
                    name="trash"
                    onClick={() => handleDeleteDiscountValue(i)}
                    cursor="pointer"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Column>
            <Button
              type="submit"
              onClick={handleAddDiscountValue}
              disabled={isButtonDisabled}
              style={{ width: "max-content" }}
            >
              + Hinzufügen
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  }
);
