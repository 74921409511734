type IAuthState = {
    pending: boolean
    success: boolean
    error: boolean
    user: any
  }

const initialState: IAuthState = {
    pending: false,
    success: false,
    error: false,
    user: null
  }
  
  export default initialState
  