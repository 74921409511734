type IMessageState = {
    isVisible: boolean,
    messageCategory: number,
    title: string,
    subTitle: string
  }

const initialState: IMessageState = {
    isVisible: false,
    messageCategory: null!,
    title: null!,
    subTitle: null!
}
  
export default initialState