import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Grid, Header, Image, Message as SMessage, Container, Checkbox } from 'semantic-ui-react'
import { closeMessage as closeMessageAction } from '../../store/message/action';

const Message: FC<any> = () => {
    const dispatch = useDispatch();
    const message = useSelector((state: any) => state.message);

    // 0 = neutral
    // 1 = positive
    // 2 = negative
    // 3 = info

    if(!message.isVisible){
        return null;
    }

    return (
        <SMessage 
            positive={message.messageCategory===1}
            negative={message.messageCategory===2}
            info={message.messageCategory===3}
            onDismiss={() => dispatch(closeMessageAction())}
        >
            <SMessage.Header>{message.title}</SMessage.Header>
            <p>{message.subTitle}</p>
        </SMessage>
    )
}

export default Message;