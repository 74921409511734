import React, { useState } from 'react';
import { Button, Form, Grid, Header, Image, Icon, Container, Table } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTenant as addTenantAPI } from '../../../service/apis';

const TenantAddScreen: React.ComponentType<any> = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [pendingForm, setPendingForm] = useState(false);
    const [name, setName] = useState('');
    const [postcode, setPostcode] = useState('');
    const [exseBaseUrl, setExseBaseUrl] = useState('');
    const [exseUsername, setExseUsername] = useState('');
    const [exsePassword, setExsePassword] = useState('');

    const handleAddTanent = () => {
        setPendingForm(true)
        addTenantAPI(name, parseInt(postcode), exseBaseUrl, exseUsername, exsePassword)
        .then((res) => {
            setPendingForm(false)
            history.goBack()
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb />
                <Form loading={pendingForm}>
                    <Header as='h3'>Mandant hinzufügen</Header>
                    <br />
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Name' type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Input fluid label='PLZ' type="text" placeholder='PLZ' value={postcode} onChange={(e) => setPostcode((e.target.value).replace(/[^\d]/g, ''))} width={6} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Endpoint URL' type="text" placeholder='Endpoint URL' value={exseBaseUrl} onChange={(e) => setExseBaseUrl(e.target.value)} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='API USER' type="text" placeholder='Mediennummer' value={exseUsername} onChange={(e) => setExseUsername(e.target.value)} />
                        <Form.Input fluid label='API Passwort' type="text" placeholder='Mediennummer' value={exsePassword} onChange={(e) => setExsePassword(e.target.value)} />
                    </Form.Group>
                    <br /><br />
                    <Button type='submit' floated='right' onClick={handleAddTanent} positive>Erstellen</Button>
                    <Button type='submit' floated='right' onClick={() => history.goBack()} >Abbrechen</Button>
                </Form>
            </Container>
        </div>
    )
}

export default TenantAddScreen;