import customAxios from './interceptors';

const postCreditService = (tenantID: string, mediumNumber: number, creditAmount: number, exseOriginatorId: string) => {
  return customAxios.post(`${process.env.REACT_APP_API_URL}/pod/discount/`, {
    tenantID,
    mediumNumber,
    creditAmount,
    exseOriginatorId,
  });
}

export default postCreditService;
