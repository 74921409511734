import customAxios from '../interceptors';

const addTenant = (name: string, postcode: number, exseBaseUrl: string, exseUsername: string, exsePassword: string) => {
  return customAxios.post(`${process.env.REACT_APP_API_URL}/tenants/`, {
    name,
    postcode,
    exseBaseUrl,
    exseUsername,
    exsePassword
  });
}

export default addTenant;