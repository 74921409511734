import React, { useEffect } from 'react';
import { Button, Form, Grid, Header, Image, Icon, Container, Table } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenants as fetchTenantsAction } from '../../../store/getTenants/action';
import { useHistory } from 'react-router-dom';

const TenantScreen: React.ComponentType<any> = () => {
    const dispatch = useDispatch(); 
    const history = useHistory();
    const getTenants = useSelector((state: any) => state.getTenants);

    useEffect(
        () => {
            dispatch(fetchTenantsAction());
          return () => {};
        }, []
    );

    const renderRow = (tenant: any) => {
        return (
            <Table.Row key={tenant?._id}>
                <Table.Cell>{tenant.postcode}</Table.Cell>
                <Table.Cell>{tenant.name}</Table.Cell>
                <Table.Cell>{tenant.countOfUsers}</Table.Cell>
                <Table.Cell>{tenant.exseBaseUrl}</Table.Cell>
                <Table.Cell>
                    <div 
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push({pathname: '/admin/tenants/edit', state: { tenant: tenant } })}
                    >
                        <Icon name='pencil' />
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    }

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb isAddTenantButton />
                <Form loading={getTenants.pending}>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>PLZ</Table.HeaderCell>
                                <Table.HeaderCell>Mandant</Table.HeaderCell>
                                <Table.HeaderCell>Anz. Benutzer</Table.HeaderCell>
                                <Table.HeaderCell>Endpoint</Table.HeaderCell>
                                <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                getTenants.data && getTenants.data.map((tenant: any) => renderRow(tenant))
                            }
                        </Table.Body>
                    </Table>
                </Form>
            </Container>
        </div>
    )
}

export default TenantScreen;