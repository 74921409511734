import { POST_CREDIT_SERVICE_PENDING, POST_CREDIT_SERVICE_SUCCESS, POST_CREDIT_SERVICE_ERROR, POST_CREDIT_SERVICE_INIT } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_CREDIT_SERVICE_INIT:
      return {
        ...state,
        pending: false,
        success: false,
        error: false,
        data: null,
        params: null
      }

    case POST_CREDIT_SERVICE_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
        data: null,
        params: null
      }

    case POST_CREDIT_SERVICE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data,
        params: action.params
      }

    case POST_CREDIT_SERVICE_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        data: null,
        params: action.params
      }

    default:
      return state
  }
}

export default reducer