import React, {useEffect, useState} from 'react';
import { Button, Form, Grid, Header, Image, Icon, Container, Table } from 'semantic-ui-react'
import TopBar from '../../../components/topBar/topBar';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser as addUserAPI } from '../../../service/apis';
import TenantDropDown from '../../../components/forms/tenantDropDown/tenantDropDown';
import {roleTypes} from '../../../interfaces/roleType';
import UserRoleDropDown from '../../../components/forms/userRoleDropDown/userRoleDropDown';
import jwt from 'jsonwebtoken';

const UserAddScreen: React.FC<any> = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [pendingForm, setPendingForm] = useState(false);
    const [username, setUsername] = useState('');
    const [tenantID, setTenantID] = useState(null);
    const [userRole, setUserRole] = useState(roleTypes.USER);
    const [userFromJwt, setUserFromJwt] = useState({});
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [exseOriginatorId, setExseOriginatorId] = useState('');

    useEffect(() => {
        const token = jwt.decode(localStorage.getItem('jwt') || '') as {data: any}
        setUserFromJwt(token?.data || {});
    }, []);

    const handleAddUser = () => {
        if(password !== passwordConfirm){
            alert('Password nicht gleich');
            return;
        }
        setPendingForm(true)
        addUserAPI(username, tenantID + '', userRole, password, exseOriginatorId)
        .then((res) => {
            setPendingForm(false)
            history.goBack()
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleSetTenantID = (value: any) => setTenantID(value)

    return (
        <div>
            <TopBar />
            <Container>
                <Breadcrumb />
                <Form loading={pendingForm}>
                    <Header as='h3'>Benutzer hinzufügen</Header>
                    <br />
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Benutzername' type="text" placeholder='Name' value={username} onChange={(e) => setUsername(e.target.value)} />
                        <TenantDropDown onChange={handleSetTenantID} value={tenantID} />
                        <UserRoleDropDown user={userFromJwt} onChange={(v: any) => setUserRole(v)} value={userRole} />
                        <Form.Input fluid label='Kundennummer' type="text" placeholder='0.0.0' value={exseOriginatorId} onChange={(e) => setExseOriginatorId(e.target.value)} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Passwort' type="text" placeholder='Passwort' value={password} onChange={(e) => setPassword(e.target.value)} />
                       <Form.Input fluid label='Passwort wiederholen' type="text" placeholder='Passwort' value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                    </Form.Group>
                    <br /><br />
                    <Button type='submit' floated='right' onClick={handleAddUser} positive>Erstellen</Button>
                    <Button type='submit' floated='right' onClick={() => history.goBack()} >Abbrechen</Button>
                </Form>
            </Container>
        </div>
    )
}

export default UserAddScreen;
