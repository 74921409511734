import React, { Dispatch, memo, SetStateAction, useCallback } from "react";
import { Button, Form, Grid, Icon } from "semantic-ui-react";

interface DeviceIDsProps {
  deviceIDs: string[];
  setDeviceIDs: Dispatch<SetStateAction<string[]>>;
}

export const DeviceIDs: React.FC<DeviceIDsProps> = memo(
  ({ deviceIDs, setDeviceIDs }) => {
    const handleChangeDeviceID = useCallback(
      (value, index) => {
        deviceIDs[index] = value;
        setDeviceIDs([...deviceIDs]);
      },
      [deviceIDs, setDeviceIDs]
    );

    const handleAddDeviceID = useCallback(() => {
      deviceIDs.push("");
      setDeviceIDs([...deviceIDs]);
    }, [deviceIDs, setDeviceIDs]);

    const handleDeleteDeviceID = useCallback(
      (i) => {
        setDeviceIDs([...deviceIDs.filter((_, index) => index !== i)]);
      },
      [deviceIDs, setDeviceIDs]
    );

    if (!deviceIDs.length) {
      handleAddDeviceID();
    }

    return (
      <Grid.Column>
        <Grid>
          {deviceIDs.map((deviceID, i) => (
            <Grid.Row key={i} columns={2} verticalAlign="middle">
              <Grid.Column width={12}>
                <Form.Input
                  value={deviceID}
                  type={"text"}
                  max="999.99"
                  onChange={(e) => {
                    handleChangeDeviceID(e.target.value, i);
                  }}
                  label={i === 0 && "Geräte-IDs"}
                />
              </Grid.Column>
              <Grid.Column width={4} cursor="pointer">
                {i !== 0 && (
                  <Icon name="trash" onClick={() => handleDeleteDeviceID(i)} />
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Column>
            <Button
              type="submit"
              onClick={handleAddDeviceID}
              style={{ width: "max-content" }}
            >
              + Hinzufügen
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  }
);
