import React, { FC, useState  } from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { login as loginAction } from '../../store/auth/action';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

const LoginScreen: FC<any> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const auth = useSelector((state: any) => state.auth);
    
    if(!auth.success || auth.user){
        //history.replace('/dashboard')
    }

    const login = () => {
        dispatch(loginAction(username, password))
    }

    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='teal' textAlign='center'>
                    Bitte melden Sie sich an
                </Header>
                <Form size='large'>
                    <Segment stacked>
                    <Form.Input 
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='Benutzername'
                        value={username}
                        onChange={(e: any) => setUsername(e.target.value)}
                    />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Passwort'
                        type='password'
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                    <Button color='teal' fluid size='large' onClick={login}> 
                        Anmelden
                    </Button>
                        { auth.success === false && auth.error &&
                          <div>
                              Incorrect credentials
                          </div>
                        }
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid>
    )
}

export default LoginScreen;