import customAxios from '../interceptors';

const addUser = (username: string, tenantID: string, role: string, password: string, exseOriginatorId: string) => {
  return customAxios.post(`${process.env.REACT_APP_API_URL}/users/`, {
    username,
    tenantID,
    role,
    password,
    exseOriginatorId
  });
}

export default addUser;
