import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './auth/reducer'
import navigationReducer from './navigation/reducer'
import getMediumDataReducer from './getMediumData/reducer'
import getTenantsReducer from './getTenants/reducer'
import postCreditServiceReducer from './postCreditService/reducer'
import messageReducer from './message/reducer'
import getUsersReducer from './getUsers/reducer'

const combiReducers = combineReducers({
  auth: authReducer,
  navigation: navigationReducer,
  getMediumData: getMediumDataReducer,
  getTenants: getTenantsReducer,
  postCreditService: postCreditServiceReducer,
  message: messageReducer,
  getUsers: getUsersReducer,
})

const store = createStore(combiReducers, compose(applyMiddleware(thunk)))

export default store
