import { GET_TENANTS_PENDING, GET_TENANTS_SUCCESS, GET_TENANTS_ERROR, GET_TENANTS_INIT } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TENANTS_INIT:
      return {
        ...state,
        pending: false,
        success: false,
        error: false,
        data: null
      }

    case GET_TENANTS_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
        data: null
      }

    case GET_TENANTS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data
      }

    case GET_TENANTS_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        data: null
      }

    default:
      return state
  }
}

export default reducer