import React, { FC, useState } from 'react';
import { Button, Form, Grid, Header, Item } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux';
import { postCreditService as postCreditServiceAction, postCreditServiceInit } from '../../../../store/postCreditService/action'
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import { getMediumDataInit } from '../../../../store/getMediumData/action';

const EnterAmountWidget: FC<any> = () => {
    const dispatch = useDispatch();
    const [creditAmount, setCreditAmount] = useState('');
    const mediumData = useSelector((state: any) => state.getMediumData);
    const postCreditService = useSelector((state: any) => state.postCreditService);
    
    const pressPostCreditService = () => {
        const creditAmountInCent: number = Number(parseFloat(creditAmount.replace(/,/g, ".")).toFixed(2)) * 100
        dispatch(postCreditServiceAction(mediumData.data.mediumNumber, creditAmountInCent))
    }

    const pressNewMediumButton = () => {
        dispatch(getMediumDataInit());
        dispatch(postCreditServiceInit());
    }

    return (
        <div>
            <Breadcrumb backButtonFunc={pressNewMediumButton} />
            <Form loading={postCreditService.pending}>
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Header as='h3'>{`Mediennummer Nr. ${mediumData.data.mediumNumber}`}</Header>
                        <br />
                        <Item.Group>
                            <Item>
                                <Item.Content style={{ flex: 1 }}>Parkartikel</Item.Content>
                                <Item.Content style={{ flex: 2 }}>{mediumData.data.parkingArticle}</Item.Content>
                            </Item>
                            <Item>
                                <Item.Content style={{ flex: 1 }}>Einfahrt</Item.Content>
                                <Item.Content style={{ flex: 2 }}>{mediumData.data.entranceDate}</Item.Content>
                            </Item>
                            <Item>
                                <Item.Content style={{ flex: 1 }}>Anlage</Item.Content>
                                <Item.Content style={{ flex: 2 }}>{mediumData.data.currentZoneName}</Item.Content>
                            </Item>
                        </Item.Group>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Header as='h3'>Befreiung</Header>
                            <br />
                            <Form.Group widths='equal'>
                                <Form.Input 
                                    fluid
                                    label='Befreiungbetrag in €'
                                    placeholder='0,00'
                                    value={creditAmount}
                                    onChange={(e) => setCreditAmount((e.target.value).replace(/[^\d,]/g, ''))}
                                />
                            </Form.Group>
                    </Grid.Column>
                </Grid>
                <br /> <br />
                <Button type='submit' floated='right' onClick={pressPostCreditService}>Bestätigen</Button>
                <br/> <br/>
            </Form>
        </div>
    )
}

export default EnterAmountWidget;