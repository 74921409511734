import React, { FC } from 'react';
import { Form } from 'semantic-ui-react'
import { roleTypes } from '../../../interfaces/roleType';

const UserRoleDropDown: FC<any> = ({ onChange, user, value }) => {
    const roleOptions = [
        {
            key: roleTypes.USER,
            text: 'Benutzer',
            value: roleTypes.USER,
        },
        {
            key: roleTypes.TENANT_ADMIN,
            text: 'Mandantenadministrator',
            value: roleTypes.TENANT_ADMIN,
        },
    ];

    if (user.role === roleTypes.ADMINISTRATOR) {
        roleOptions.push(        {
            key: roleTypes.ADMINISTRATOR,
            text: 'Administrator',
            value: roleTypes.ADMINISTRATOR,
        })
    };

    return (
      <Form.Dropdown
        fluid
        label='Benuzterrolle'
        placeholder='State'
        search
        selection
        options={roleOptions}
        value={value}
        onChange={(e: any, { value }: any) => onChange(value)}
        style={{ marginTop: 4 }}
      />
    )
}

export default UserRoleDropDown;
