import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR } from './types';
import { login as loginAPI } from '../../service/apis';
import jwt from 'jsonwebtoken';

const loginPending = () => {
  return {
    type: LOGIN_PENDING,
  }
}

const loginSuccess = (user: any) => {
  return {
    type: LOGIN_SUCCESS,
    user: user,
  }
}

const loginError = () => {
  return {
    type: LOGIN_ERROR,
  }
}

export const setAuth = (user: any) => {
  return (dispatch: any) => {
    dispatch(loginSuccess(user));
  }
}

export const logout = () => {
  localStorage.removeItem('jwt');
  window.location.reload();
}

export const login = (username: string, password: string) => {
  return (dispatch: any) => {
    dispatch(loginPending());

    loginAPI(username, password).then(
      (res: any) => {
        localStorage.setItem('jwt', res.jwt);
        dispatch(loginSuccess(res.user));
        window.location.replace(new URL('/', window.location.href).href);
      }).catch((error: any) => {
        console.log(error);
        dispatch(loginError());
      });
  }
}
