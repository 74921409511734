import jwt from 'jsonwebtoken'
import customAxios from '../interceptors';

const login = (username: string, password: string) => {
  const passwordEncoded = jwt.sign({ data: password }, process.env.REACT_APP_USER_TRANSFER_PASSWORD_SALT + '', { expiresIn: 123456789 });

  return customAxios.post(`${process.env.REACT_APP_API_URL}/login`, {
    username: username,
    password: passwordEncoded
  }).then((res: any) => {
    return res.data.data;
  }).catch((error: any) => {
    console.log(error)
  })
}

export default login;
