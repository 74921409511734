import { GET_MEDIUM_DATA_PENDING, GET_MEDIUM_DATA_SUCCESS, GET_MEDIUM_DATA_ERROR, GET_MEDIUM_DATA_INIT } from './types'
import { getMediumData as getMediumDataAPI } from '../../service/apis/'
import { showMessage as showMessageAction } from '../message/action'
import store from '../store'

const getMediumDataPending = () => {
  return {
    type: GET_MEDIUM_DATA_PENDING,
  }
}

const getMediumDataSuccess = (data: any) => {
  return {
    type: GET_MEDIUM_DATA_SUCCESS,
    data,
  }
}

const getMediumDataError = () => {
  return {
    type: GET_MEDIUM_DATA_ERROR
  }
}

export const getMediumDataInit = () => {
  return {
    type: GET_MEDIUM_DATA_INIT
  }
}

export const fetchMediumData = (mediumNumber: number) => {
    const tenantID = store.getState().auth.user.tenantID;
    return (dispatch: any) => {
      dispatch(getMediumDataPending())
      
      getMediumDataAPI(mediumNumber, tenantID)
        .then(async (resp: any) => {
          const isObjectValid = validation(resp.data.data);

          if(isObjectValid){
            const mediumData = await createMediumData(resp.data.data)
            dispatch(getMediumDataSuccess(mediumData))
          } else {
            dispatch(getMediumDataError())
            dispatch(showMessageAction(2, 'Medium konnte nicht geladen werden.', 'Objekt nicht passend'))
          }
        })
        .catch((err: any) => {
          dispatch(getMediumDataError())
          dispatch(showMessageAction(2, 'Medium konnte nicht geladen werden.', err + ''))
        })
    }
}

const validation = (data: any) => {
  let isValid = true;
  let result = data.Performances.filter(
    (o: any) => ((o.SellingRule === 1001 || o.SellingRule === 1060) && o.ActivatedAt != null)
  );

  if(result.length === 0){
    isValid = false;
  }

  return isValid;
}

const createMediumData = (data: any) => {
  let result = data.Performances.filter(
    (o: any) => ((o.SellingRule === 1001 || o.SellingRule === 1060) && o.ActivatedAt != null)
  );
  result = result[0];

  
  return {
    mediumNumber: data.Medium.Number,
    parkingArticle: result.Shorttext,
    entranceDate: result.ActivatedAt,
    currentZoneName: data.Medium.CurrentZone.Name
  }

}
  